import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { CheckoutItemProps } from '../types/checkout';

const CheckoutItem: React.FC<CheckoutItemProps> = (item) => {
  return (
    <Box>
      <Text>{item.product}</Text>
      <Text>{item.cost}</Text>
    </Box>
  );
};

export default CheckoutItem;