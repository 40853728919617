import { Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavItemProps {
  icon?: any;
  to: string;
}

export const NavItem: React.FC<NavItemProps> = ({ to, icon, children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === to;

  return (
    <Flex
      align="center"
      px={4}
      mx={2}
      my={1}
      rounded="md"
      py={3}
      cursor="pointer"
      color={isActive ? 'whiteAlpha.900' : 'whiteAlpha.700'}
      bg={isActive ? 'blackAlpha.300' : ''}
      _hover={{
        bg: 'blackAlpha.300',
        color: 'whiteAlpha.900',
      }}
      role="group"
      fontWeight="semibold"
      onClick={() => navigate(to)}
      {...rest}
    >
      {icon && <Icon mr={2} boxSize={4} as={icon} />}
      {children}
    </Flex>
  );
};
