import React, { memo } from 'react';
import { VStack } from '@chakra-ui/react';
import FeedItem from './FeedItem';
import { IFeedItem } from '../../types/feed';

interface FeedListProps {
  feed: IFeedItem[];
  handlePostLike: (id: number) => void;
}

const FeedList: React.FC<FeedListProps> = ({ feed, handlePostLike }) => {
  return (
    <VStack>
      {feed.map((item) => (
        <FeedItem key={item.id} item={item} handlePostLike={handlePostLike} />
      ))}
    </VStack>
  );
};

export default memo(FeedList);
