import { UseCheckoutProps } from '../types/checkout';

export const useCheckout = (): UseCheckoutProps => {
  const createCheckoutSession = async () => {
    fetch('http://localhost:5000/checkout-session', {
      method: 'POST',
    }).then((resp) => {
      window.location.href = resp.url;
    });
  };

  return { createCheckoutSession };
};
