import { UnformattedPost } from '../types/posts';
import { useSetPostsState } from "../states/feed/feed.state";
import { useCallback } from "react";

export const usePostActions = () => {
  const setPosts = useSetPostsState();

  const createPost = async (form: UnformattedPost) => {
    await fetch('http://localhost:5000/posts/newPost', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ caption: form.caption, user: '123' }),
    });
  };

  const updatePosts = useCallback((post: any) => {
    setPosts((curPosts) => [post, ...curPosts])
  }, [setPosts]);

  return {
    createPost,
    updatePosts
  };
};
