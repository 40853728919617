import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { User } from '../../types/auth';

export const userState = {
  isTempUser: atom<boolean>({
    key: 'userIsTempUser',
    default: false,
  }),
  user: atom<User | null>({
    key: 'userUser',
    default: null,
  }),
  isAuthenticated: atom<boolean>({
    key: 'isAuthenticatedUser',
    default: false /*selector({
      key: 'defaultIsAuthenticatedUserValue',
      get: async () => {
        const res = await fetch('http://localhost:5000/auth/verifyuser', {
          method: 'GET',
          credentials: 'include',
        });
        const response = await res.json();

        if (response) return true;
        return false;
      },
    }),*/
  }),
};

// isTempUser
export const useIsTempUser = () => useRecoilState(userState.isTempUser);
export const useIsTempUserValue = () => useRecoilValue(userState.isTempUser);
export const useSetIsTempUser = () => useSetRecoilState(userState.isTempUser);

// isAuthenticated
export const useIsAuthenticated = () => useRecoilState(userState.isAuthenticated);
export const useSetIsAuthenticated = () => useSetRecoilState(userState.isAuthenticated);
export const useIsAuthenticatedValue = () => useRecoilValue(userState.isAuthenticated);

// user
export const useUser = () => useRecoilState(userState.user);
export const useUserValue = () => useRecoilValue(userState.user);
export const useSetUser = () => useSetRecoilState(userState.user);
