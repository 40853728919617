import React, { useState } from 'react';
import { Box, Button, Input, Flex, Avatar, Text } from '@chakra-ui/react';
import { useUserActions } from '../../actions/auth/user.action';
import { useUserValue } from '../../states/auth/user.state';

const ProfileForm: React.FC = () => {
  const user = useUserValue();
  const [username, setUsername] = useState<string>(user?.username || '');
  const [avatar, setAvatar] = useState<string>(user?.avatar || '');
  const { updateUserProfile } = useUserActions();

  const handleUpdateProfile = async () => {
    await updateUserProfile(user, { username, avatar });
  };

  return (
    <Flex direction="column" align="left">
      <Box>
        <Text fontSize="2xl">Profile</Text>
      </Box>
      <Box align="center">
        <Avatar mb={5} size="2xl" name={username} src={avatar} />
        <Input
          mb={5}
          placeholder="Change username"
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
        />
        <Input
          mb={5}
          placeholder="Change avatar"
          value={avatar}
          onChange={(e) => setAvatar(e.currentTarget.value)}
        />
        <Button w="100%" variant="primary" onClick={handleUpdateProfile}>
          Update profile
        </Button>
      </Box>
    </Flex>
  );
};

export default ProfileForm;
