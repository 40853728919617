import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { FormattedPost } from '../../types/posts';

export const feedState = {
  posts: atom({
    key: 'defaultPosts',
    default: selector<FormattedPost[]>({
      key: 'defaultPostsValue',
      get: async () => {
        try {
          const res = await fetch('http://localhost:5000/posts', {
            method: 'GET',
          });

          const posts = await res.json();

          return posts;
        } catch (e) {
          console.log(e);
        }
      },
    }),
  }),
};

export const usePostsValue = () => useRecoilValue(feedState.posts);
export const useSetPostsState = () => useSetRecoilState(feedState.posts);
