import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

const ProfileSettings: React.FC = () => {
  return (
    <Flex direction="column" align="left">
      <Box>
        <Text fontSize="2xl">Profile Settings</Text>
      </Box>
    </Flex>
  );
};

export default ProfileSettings;
