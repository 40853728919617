import { useSetIsAuthenticated, useSetUser } from "../../../states/auth/user.state";
import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";

export const useAuth = () => {
  const setIsAuthenticated = useSetIsAuthenticated();
  const setUser = useSetUser();
  const toast = useToast();

  const validateUser = useCallback(async () => {
    fetch('http://localhost:5000/auth/verifyuser', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json()).then((response) => {
      if (response) {
        setIsAuthenticated(true)
        setUser(response);
      } else {
        setIsAuthenticated(false)
        setUser(null)
      }
    }).catch((err) => {
      console.log(err.messsage);
      toast({
        title: 'Error',
        description: 'Failed to fetch credentials',
        status: 'error',
      })
    })

  }, [setIsAuthenticated, setUser]);

  return { validateUser }
};
