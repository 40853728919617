import React from 'react';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import './translation/i18';
import themeConfig from './theming/theme';
import { BrowserRouter } from 'react-router-dom';
import RoutesWrapper from './routes';
import '@fontsource/rubik';

function App() {
  return (
    <>
      <ChakraProvider theme={themeConfig}>
        <BrowserRouter>
          <RoutesWrapper />
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default App;
