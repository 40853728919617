import { useCallback } from 'react';
import { UpdateProfileProps } from '../../types/auth';

interface UserActionsProps {
  updateUserProfile: (user: any, options: UpdateProfileProps) => Promise<void>;
}

export const useUserActions = (): UserActionsProps => {
  const updateUserProfile = useCallback(async (options: UpdateProfileProps) => {
    console.log('updated user with ', options);
  }, []);

  return {
    updateUserProfile,
  };
};
