import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Auth/Login/Login';
import Header from './components/Header/Header';
import RegisterSteps from './features/auth/Register/RegisterSteps';
import Dashboard from './features/dashboard/Dashboard';
import Profile from './components/Profile/Profile';
import { RequireAuth } from './components/Auth/RouteUtils';
import ProfileSettings from './components/Profile/ProfileSettings';
import ProfileForm from './components/Profile/ProfileForm';
import Post from './components/Post/Post';
import CreatePost from './components/Post/Create/CreatePost';
import { CircularProgress } from '@chakra-ui/react';
import Checkout from './features/checkout/Checkout';

const RoutesWrapper: React.FC = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <React.Suspense fallback={<CircularProgress isIndeterminate />}>
                <Dashboard />
              </React.Suspense>
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegisterSteps />} />

        <Route path="/post" element={<Post />}>
          <Route path="new" element={<CreatePost />} />
          <Route path=":id" />
        </Route>

        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <RequireAuth>
                <ProfileForm />
              </RequireAuth>
            }
          />
          <Route
            path="settings"
            element={
              <RequireAuth>
                <ProfileSettings />
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path="cart"
          element={
            <RequireAuth>
              <Checkout />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default RoutesWrapper;
