import React from 'react';
import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  HStack,
  VStack,
} from '@chakra-ui/react';
import DrawerLink from '../Header/components/DrawerLink';
import { useTranslation } from 'react-i18next';
import { useUserValue } from '../../states/auth/user.state';

type NavigationDrawerProps = {
  isAuthenticated: boolean;
  open: boolean;
  handleClose: () => void;
};

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  open,
  handleClose,
  isAuthenticated,
}) => {
  const { t } = useTranslation();
  const user = useUserValue();

  return (
    <Drawer placement="right" isOpen={open} onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {!isAuthenticated && (
          <>
            <DrawerHeader borderBottomWidth={1}>
              <Text>{t('AppTitle')}</Text>
            </DrawerHeader>
            <DrawerBody>
              <Flex w="100%" direction="column" justify="flex-start" align="flex-start">
                <VStack display="flex" align="flex-start" spacing={4}>
                  <DrawerLink close={handleClose} to="/login" name={t('Navigation.Link.Login')} />
                  <DrawerLink
                    close={handleClose}
                    to="/register"
                    name={t('Navigation.Link.SignUp')}
                  />
                </VStack>
              </Flex>
            </DrawerBody>
          </>
        )}
        {isAuthenticated && (
          <>
            <DrawerHeader borderBottomWidth={1}>
              <HStack>
                <Avatar src={user?.avatar} />
                <Text>{user?.username}</Text>
              </HStack>
            </DrawerHeader>
            <DrawerBody>
              <Flex w="100%" direction="column" justify="flex-start" align="flex-start">
                <VStack display="flex" align="flex-start" spacing={4}>
                  <DrawerLink close={handleClose} to="/" name={t('Navigation.Link.Home')} />
                  <DrawerLink
                    close={handleClose}
                    to="/profile"
                    name={t('Navigation.Link.Profile')}
                  />
                </VStack>
              </Flex>
            </DrawerBody>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default NavigationDrawer;
