import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import HeaderMenu from './HeaderMenu';
import { useNavigate } from 'react-router-dom';
import { useSetPostModal } from '../../states/posts/posts.state';
import NavigationDrawer from '../NavigationDrawer/NavigationDrawer';
import { FiMenu } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticatedValue } from '../../states/auth/user.state';

const Header: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticatedValue();

  const setPostModal = useSetPostModal();

  const isDrawerVisible = useBreakpointValue({ base: true, sm: true, md: false, lg: false });
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const openPostModal = () => {
    setPostModal(true);
  };

  return (
    <>
      {!isDrawerVisible ? (
        <Flex justify="space-between" align="center">
          <Box p={2}>
            <Heading>{t('AppTitle')}</Heading>
          </Box>
          <Spacer />
          {isAuthenticated && (
            <Box p={2}>
              <Button mr={4} onClick={() => navigate('/')} variant="link" color="white">
                Feed
              </Button>
              <Button variant="link" color="white">
                Events
              </Button>
            </Box>
          )}
          <Spacer />
          <Box p={2}>
            {!isAuthenticated && (
              <>
                <Button mr={4} variant="link" onClick={() => navigate('/login')}>
                  Sign in
                </Button>
                <Button mr={4} variant="link" onClick={() => navigate('/register')}>
                  Sign up
                </Button>
              </>
            )}
            {isAuthenticated && (
              <>
                <HeaderMenu />
              </>
            )}
          </Box>
        </Flex>
      ) : (
        <Flex p={2} justify="flex-end">
          <IconButton aria-label="Open drawer" icon={<FiMenu />} onClick={handleOpenDrawer}>
            Drawer
          </IconButton>
          <NavigationDrawer
            isAuthenticated={isAuthenticated}
            open={openDrawer}
            handleClose={handleCloseDrawer}
          />
        </Flex>
      )}
    </>
  );
};

export default Header;
