import React from 'react';
import { Box, Button, Heading } from "@chakra-ui/react";
import CheckoutItem from './components/CheckoutItem';
import { useCheckout } from "./api/useCheckout";

const Checkout: React.FC = () => {
  const { createCheckoutSession } = useCheckout();

  return (
    <Box>
      <Heading>Checkout</Heading>
      <CheckoutItem product="Bunny avatar" cost={300} />
      <CheckoutItem product="House" cost={1000} />
      <Button onClick={createCheckoutSession} variant="primary">Checkout</Button>
    </Box>
  );
};

export default Checkout;
