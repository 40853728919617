import React, { useState } from 'react';
import { Box, VStack, Stack, Heading, Button, Divider, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ConnectSignupForm } from './form/ConnectSignupForm';
import { RegisterForm } from '../../../types/auth';
import { useSignupAPI } from '../api/useSignupAPI';

const RegisterSteps: React.FC = () => {
  const methods = useForm<RegisterForm>({ defaultValues: { email: '', username: '' } });
  const { t } = useTranslation();
  const signup = useSignupAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSignup = async (formData: RegisterForm) => {
    setIsLoading(true);
    signup(formData).then((result: any) => {
      if (result.success) {
        setIsLoading(false);
        navigate('/login');
        toast({
          status: 'success',
          title: 'Success',
          description:
            'Congratulations, you have successfully created your user. Now you can log in!',
          duration: 5000,
        });
      }
    });
  };

  return (
    <Box display="flex" alignItems="center" height="80vh">
      <VStack w="100%">
        <Box w={{ base: '90%', sm: '90%', md: '50%', lg: '30%' }} paddingBottom={5}>
          <Heading textAlign="left">Sign up</Heading>
        </Box>
        <FormProvider {...methods}>
          <Stack w={{ base: '90%', sm: '90%', md: '50%', lg: '30%' }}>
            <form onSubmit={methods.handleSubmit(handleSignup)}>
              <ConnectSignupForm />

              <Box paddingTop={5} w="100%">
                <Button isLoading={isLoading} variant="primary" type="submit" w="100%">
                  {t('Actions.Auth.SignUp')}
                </Button>
              </Box>

              <VStack paddingTop={5}>
                <Divider />
                <Link to="/login">
                  <Button variant="link">{t('Navigation.Auth.AlreadyHasAccount')}</Button>
                </Link>
              </VStack>
            </form>
          </Stack>
        </FormProvider>
      </VStack>
    </Box>
  );
};

export default RegisterSteps;
