import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export const postsState = {
  postModal: atom<boolean>({
    key: 'postsPostModal',
    default: false
  })
};

export const usePostModalValue = () => useRecoilValue(postsState.postModal);
export const useSetPostModal = () => useSetRecoilState(postsState.postModal);