import { extendTheme } from '@chakra-ui/react';
import { ButtonStyles as Button } from './components/button';

const themeConfig = extendTheme({
  fonts: {
    heading: 'Rubik',
    body: 'Rubik',
  },
  colors: {
    cardDark: '#1A202C',
    cardLight: '',
    primary: '#2493FA',
    secondary: '#393746',
    highlighted: '#52acff',
    section: {
      defaultDark: 'blackAlpha.300',
      defaultLight: 'whiteAlpha.300'
    },
  },
  components: {
    Button,
  },
});

export default themeConfig;
