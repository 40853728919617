import React from "react";
import { Box } from "@chakra-ui/react";
import CreatePostForm from "./CreatePostForm";

const CreatePost: React.FC = () => {
  return (
    <Box>
      <CreatePostForm />
    </Box>
  )
}

export default CreatePost;