import React, { useCallback, useEffect } from 'react';
import FeedList from './FeedList';
import { usePostsValue } from '../../states/feed/feed.state';
import { usePostApi } from './api/usePostApi';
import { io } from 'socket.io-client';
import { usePostActions } from '../../actions/posts.action';
import { UnformattedPost } from '../../types/posts';
import { Box, Button, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

const Feed: React.FC = () => {
  // TODO: Fix this any. Figure out typings in firebase
  const posts: any = usePostsValue();
  const { likePost } = usePostApi();
  const { createPost, updatePosts } = usePostActions();
  const [t] = useTranslation();
  const { register, handleSubmit, reset } = useForm<UnformattedPost>();

  const socket = io('http://localhost:5000');

  const handlePostLike = useCallback(
    async (id: number) => {
      await likePost(id);
    },
    [likePost],
  );

  const handleCreatePost = (data: UnformattedPost) => {
    createPost(data);
    reset({ caption: '' });
  };

  useEffect(() => {
    socket.on('newPost', (data) => {
      updatePosts(data);
    });
  }, []);

  return (
    <>
      <Box mb={5}>
        <form onSubmit={handleSubmit(handleCreatePost)}>
          <Box mb={2}>
            <Textarea
              h={100}
              placeholder={t('Modal.PostModal.CaptionPlaceholder')}
              resize="none"
              {...register('caption')}
            />
          </Box>
          <Button w="100%" type="submit" variant="primary">
            {t('Generic.Post')}
          </Button>
        </form>
      </Box>
      <FeedList feed={posts} handlePostLike={handlePostLike} />;
    </>
  );
};

export default Feed;
