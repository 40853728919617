import React from 'react';
import { Box } from '@chakra-ui/react';
import Feed from '../feed/Feed';

const Dashboard: React.FC = () => {

  return (
    <Box w="100%" display="flex" justifyContent="center" p={2}>
      <Box w={{ base: '100%', sm: '100%', md: '50%', lg: '50%' }}>
          <Feed />
      </Box>
    </Box>
  );
};

export default Dashboard;
