import React from 'react';
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type DrawerLinkProps = {
  to: string;
  name: string;
  close: () => void;
}

const DrawerLink: React.FC<DrawerLinkProps> = ({ to, name, close }) => {
  const navigate = useNavigate();

  const handleClickLink = () => {
    navigate(to);
    close()
  }

  return (
    <Button variant="link" onClick={handleClickLink}>{name}</Button>
  )
};

export default DrawerLink;