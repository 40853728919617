import React from 'react';
import { Divider, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { FiMoreVertical, FiEdit } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';

const FeedItemMenu: React.FC = () => {
  return (
    <Menu placement="start-start">
      <MenuButton as={IconButton} aria-label="Options" icon={<FiMoreVertical />} />
      <MenuList p={0}>
        <MenuItem icon={<MdDelete size={16} />}>Delete</MenuItem>
        <Divider />
        <MenuItem icon={<FiEdit size={16} />}>Edit</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default FeedItemMenu;

