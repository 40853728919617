import { LoginForm } from '../../../types/auth';

export const useLoginAPI = () => {
  return async (user: LoginForm) =>
    new Promise((resolve, reject) => {
      fetch('http://localhost:5000/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(user),
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result);
        })
        .catch((err) => reject(err));
    });
};
