import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useIsAuthenticatedValue } from '../../states/auth/user.state';
import { useAuth } from '../../features/auth/hooks/useAuth';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useIsAuthenticatedValue();
  const location = useLocation();
  const { validateUser } = useAuth();

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  // should this really be called each time? Maybe do something like react-query and chache?

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};
