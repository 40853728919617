import React from 'react';
import { useFormContext } from 'react-hook-form';
import SignupForm from './SignupForm';
import { RegisterForm } from '../../../../types/auth';

export const ConnectSignupForm: React.FC = () => {
  const methods = useFormContext<RegisterForm>();

  return <SignupForm {...methods} />;
};
