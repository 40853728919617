import React, { useState } from 'react';
import { Input, Stack, InputGroup, InputLeftElement, Text } from '@chakra-ui/react';
import { FiKey, FiMail, FiUser } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { RegisterForm } from '../../../../types/auth';
import debounce from 'lodash.debounce';
import { useAuthValidation } from '../../api/useAuthValidation';

const SignupForm: React.FC<UseFormReturn<RegisterForm>> = ({
  register,
  setError,
  clearErrors,
  formState: { errors },
}) => {
  const { t } = useTranslation();
  const { validateEmail, validateUsername } = useAuthValidation();
  const [doesEmailExist, setDoesEmailExist] = useState<boolean>(false);
  const [isUsernameTaken, setIsUsernameTaken] = useState<boolean>(false);

  // checks if the email is already taken by another account
  const checkValidEmail = async (e: any) => {
    validateEmail(e.target.value)
      .then((emailExists) => {
        if (emailExists) {
          setDoesEmailExist(true);
          return setError('email', {
            type: 'manual',
            message: 'This email is already registered',
          });
        }

        setDoesEmailExist(false);
        return clearErrors('email');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkValidUsername = async (e: any) => {
    validateUsername(e.target.value)
      .then((usernameExists) => {
        if (usernameExists) {
          setIsUsernameTaken(true);
          return setError('username', {
            type: 'manual',
            message: 'This username is already taken',
          });
        }
        setIsUsernameTaken(false);
        return clearErrors('username');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Stack spacing={4}>
        <Stack>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<FiMail />} />
            <Input
              {...register('email', {
                required: {
                  value: true,
                  message: 'You need to fill in your email.',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please type in a correct email.',
                },
              })}
              onChange={debounce(checkValidEmail, 1000)}
              placeholder={t('Auth.SignupEmailPlaceholder')}
              isInvalid={doesEmailExist || errors.email?.type === 'required'}
            />
          </InputGroup>
          <Text color="red.300">{errors.email && errors.email?.message}</Text>
        </Stack>

        <Stack>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<FiUser />} />
            <Input
              {...register('username', {
                required: {
                  value: true,
                  message: 'You need to fill in a username.',
                },
              })}
              placeholder={t('Auth.SignupUsernamePlaceholder')}
              onChange={debounce(checkValidUsername, 1000)}
              isInvalid={isUsernameTaken || errors.username?.type === 'required'}
            />
          </InputGroup>
          <Text color="red.300">{errors.username && errors.username?.message}</Text>
        </Stack>

        <Stack>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<FiKey />} />
            <Input
              {...register('password', {
                required: {
                  value: true,
                  message: 'You need to fill in a password',
                },
              })}
              placeholder={t('Auth.SignupPasswordPlaceholder')}
              type="password"
              isInvalid={isUsernameTaken || errors.username?.type === 'required'}
            />
          </InputGroup>
          <Text color="red.300">{errors.password && errors.password?.message}</Text>
        </Stack>
      </Stack>
    </>
  );
};

export default SignupForm;
