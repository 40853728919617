import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';

type PostFormData = {
  title: string;
  description: string;
  image: string;
  game: string;
};

const CreatePostForm: React.FC = () => {
  const { register, handleSubmit } = useForm<PostFormData>();

  const submit = (data: PostFormData) => {
    console.log(data);
  };

  return (
    <Box display="flex" alignItems="center" h="50vh">
      <VStack w="100%">
        <Stack w={{ base: '30%', sm: '90%', md: '50%', lg: '50%' }}>
          <form onSubmit={handleSubmit(submit)}>
            <Stack spacing={4}>
              <Input placeholder="Title" {...register('title', { required: true })} />
              <Textarea
                placeholder="Tell us something about this..."
                resize="none"
                {...register('description', { required: true })}
              />
              <Stack>
                <Text>Would you like to add the game?</Text>
                <Select {...register('game', { required: false })}>
                  <option />
                  <option>Paintball</option>
                  <option>Garden World</option>
                </Select>
              </Stack>
              <Button type="submit">Post</Button>
            </Stack>
          </form>
        </Stack>
      </VStack>
    </Box>
  );
};

export default CreatePostForm;
