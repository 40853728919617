import React from 'react';
import { Avatar, Box, Button, Divider, Flex, HStack, IconButton, Text } from "@chakra-ui/react";
import { FeedItemProps } from '../../types/feed';
import { FiMessageCircle, FiFlag } from 'react-icons/fi';
import { FaHeart } from 'react-icons/fa';
import FeedItemMenu from './FeedItemMenu';
import { useUserValue } from '../../states/auth/user.state';
import dayjs from 'dayjs';

const FeedItem: React.FC<FeedItemProps> = ({ item, handlePostLike }) => {
  const user = useUserValue();
  const isMine = item.user.id === user?.id;

  return (
    <Box w="100%" borderColor="gray.700">
      <Flex justify="space-between">
        <Box>
          <HStack>
            <Box p={2} display="flex" alignItems="center">
              <Box mr={2}>
                <Avatar size="sm" src={item.user.avatar} />
              </Box>
              <Text>{item.user.username}</Text>
              <Box ml={2}>
                <Text color="gray.400">{dayjs(item.createdAt).format("dddd hh:mm").toLowerCase()}</Text>
              </Box>
            </Box>
          </HStack>
          <Box p={2}>
            <Text>{item.caption}</Text>
          </Box>

          <Flex p={2}>
            <Box mr={2}>
              <Button
                onClick={() => handlePostLike(item.id)}
                size="sm"
                fontSize="16px"
                aria-label="Like post"
                leftIcon={<FaHeart color="#e74c3c" />}
              >
                {item.likes}
              </Button>
            </Box>
            <Box mr={2}>
              <IconButton
                size="sm"
                fontSize="18px"
                aria-label="Reply on post"
                icon={<FiMessageCircle />}
              />
            </Box>
            <Box>
              <IconButton size="sm" fontSize="18px" aria-label="Flag post" icon={<FiFlag />} />
            </Box>
          </Flex>
        </Box>
        <Box>{isMine && <FeedItemMenu />}</Box>
      </Flex>
      <Divider />
    </Box>
  );
};

export default FeedItem;
