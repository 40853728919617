import { whiten } from '@chakra-ui/theme-tools';
export const ButtonStyles = {
  baseStyle: {},
  sizes: {},
  variants: {
    headerButton: {
      bg: 'headerButton'
    },
    primary: {
      bg: 'primary',
      color: 'white',
      _hover: {
        bg: whiten('primary', 20),
      },
    },
  },
  defaultProps: {},
};
