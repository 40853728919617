import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { NavItem } from '../../ui/components/Sidebar';
import { Outlet } from 'react-router-dom';

const Profile: React.FC = () => {
  const SidebarContent = () => (
    <Box
      as="nav"
      pos="fixed"
      left={0}
      zIndex="sticky"
      h="full"
      pb={10}
      overflowX="hidden"
      overflowY="auto"
      w={60}
    >
      <Flex mt={3} direction="column" as="nav" fontSize="sm">
        <NavItem to="/profile">Profile</NavItem>
        <NavItem to="/profile/settings">Settings</NavItem>
      </Flex>
    </Box>
  );

  return (
    <Box as="section">
      <SidebarContent />
      <Box ml={{ base: 0, md: 60 }}>
        <Box as="main" p={4}>
          <Box bg="blackAlpha.300" display="flex" flexDirection="column" alignItems="left" rounded="md" p={5}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
