import React from 'react';
import { Menu, MenuButton, MenuItem, MenuList, Avatar } from '@chakra-ui/react';
import { FiUser, FiSettings, FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useUserValue } from '../../states/auth/user.state';

export const HeaderMenu: React.FC = () => {
  const navigate = useNavigate();
  const user = useUserValue();

  const handleSignOut = () => {
    console.log('signing out');
  };

  return (
    <Menu isLazy>
      <MenuButton>
        <Avatar size="sm" name={user?.username} src={user?.avatar} />
      </MenuButton>
      <MenuList mt={2}>
        <MenuItem icon={<FiUser />} onClick={() => navigate('/profile')}>
          Profile
        </MenuItem>
        <MenuItem icon={<FiSettings />} onClick={() => navigate('/profile/settings')}>
          Settings
        </MenuItem>
        <MenuItem icon={<FiLogOut />} onClick={handleSignOut}>
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default HeaderMenu;
