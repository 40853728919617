import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text, useToast,
  VStack
} from "@chakra-ui/react";
import { FiEye, FiEyeOff, FiKey, FiUser } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useIsAuthenticatedValue, useSetIsAuthenticated, useSetUser } from "../../../states/auth/user.state";
import { useTranslation } from 'react-i18next';
import { useLoginAPI } from '../../../features/auth/api/useLoginAPI';

type LoginFormData = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormData>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const loginUser = useLoginAPI();
  const setUser = useSetUser();
  const setIsAuthenticated = useSetIsAuthenticated();
  const isAuth = useIsAuthenticatedValue();
  const toast = useToast();

  const from = location?.state?.from?.pathname || '/';

  useEffect(() => {
    if (isAuth) {
      navigate(from, { replace: true });
    }
  }, [isAuth, navigate, from])

  const handleLogin = async (data: LoginFormData) => {
    loginUser(data).then((result: any) => {
      setUser(result.user);
      setIsAuthenticated(true);
      navigate(from, { replace: true });
    }).catch((err) => {
      toast({
        title: 'Error',
        description: 'Failed to login. Try again later',
        status: 'error'
      })
    });
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  if (isAuth) return null;

  return (
    <Box display="flex" alignItems="center" height="80vh">
      <VStack w="100%">
        <Box w={{ base: '90%', sm: '90%', md: '50%', lg: '30%' }} paddingBottom={5}>
          <Heading textAlign="left">Sign in</Heading>
        </Box>

        <Stack w={{ base: '90%', sm: '90%', md: '50%', lg: '30%' }}>
          <form onSubmit={handleSubmit(handleLogin)}>
            <Stack spacing={4}>
              <Stack>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FiUser />} />
                  <Input
                    placeholder={t('Auth.GenericEmailPlaceholder')}
                    {...register('username', { required: true })}
                    isInvalid={errors.username?.type === 'required'}
                  />
                </InputGroup>
                {errors.username && (
                  <Text fontWeight="bold" color="red.200">
                    {t('Auth.Error.MissingEmail')}
                  </Text>
                )}
              </Stack>

              <Stack>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FiKey />} />
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('Auth.GenericPasswordPlaceholder')}
                    {...register('password', { required: true })}
                    isInvalid={errors.password?.type === 'required'}
                  />
                  <InputRightElement
                    onClick={toggleShowPassword}
                    children={!showPassword ? <FiEye /> : <FiEyeOff />}
                  />
                </InputGroup>
                {errors.password && (
                  <Text fontWeight="bold" color="red.200">
                    {t('Auth.Error.MissingPassword')}
                  </Text>
                )}
              </Stack>

              <Button variant="primary" type="submit">
                {t('Actions.Auth.Login')}
              </Button>
            </Stack>
          </form>
        </Stack>

        <VStack>
          <Link to="/register">
            <Button variant="link">{t('Navigation.Auth.NoAccount')}</Button>
          </Link>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Login;
