import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './metafriend.locales/en.json';

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en,
    },
    lng: 'en',
  })
  .then((r) => console.log(r));

export default i18next;
