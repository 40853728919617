export const useAuthValidation = () => {
  const validateEmail = async (email: string) => {
    return new Promise((resolve, reject) => {
      fetch('http://localhost:5000/auth/verifyemail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.emailExists) resolve(true);
          resolve(false);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const validateUsername = async (username: string) => {
    return new Promise((resolve, reject) => {
      fetch('http://localhost:5000/auth/verifyusername', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.usernameExists) resolve(true);
          resolve(false);
        })
        .catch((err) => reject(err));
    });
  };

  return { validateEmail, validateUsername };
};
